import { abilityList, advantageList, coreList, brandList } from '../mixins/index.js'
import $ from 'jquery'

// 处理列表的渲染
class ListRenderer {
    constructor(listType, selector) {
        this.listType = listType;
        this.selector = selector;
    }

    // 根据提供的列表渲染项目的方法
    render(list) {
        const listSelector = $(this.selector);
        list.forEach(item => {
            listSelector.append(`<li>
                <img src="${require(`../../assets/images/${this.listType}/${item.url}`)}" alt="">
                <div>${item.title}</div>
                <div>${item.content}</div>
            </li>`);
        });
    }
    serve() {
        return new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                $('.yx-fixed').css('display', 'none')
            } else if (window.scrollY + window.innerHeight <= $('.yx-static').offset().top + $('.yx-static').height()) {
                $('.yx-fixed').css('display', 'flex')
            }
        }, {
            root: null,
            threshold: 1
        })
    }
}

window.onscroll = () => {
    ob.serve().observe($('.yx-static')[0]);
}

const abilityRenderer = new ListRenderer('org', '.yx-content-ability-ul');
const advantageRenderer = new ListRenderer('advantage', '.yx-content-advantage-ul');
const coreRenderer = new ListRenderer('core', '.yx-content-core-ul');
const brandRenderer = new ListRenderer('brand', '.yx-content-brand-ul');

abilityRenderer.render(abilityList);
advantageRenderer.render(advantageList);
coreRenderer.render(coreList);
brandRenderer.render(brandList)

const ob = new ListRenderer()
ob.serve().observe($('.yx-static')[0]);
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.woff2?t=1725869128183", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("iconfont.woff?t=1725869128183", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("iconfont.ttf?t=1725869128183", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "iconfont"; /* Project id 4591508 */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiazai1:before {
  content: "\\e637";
}

.icon-yindaojiantou:before {
  content: "\\e72e";
}

.icon-success:before {
  content: "\\e67f";
}

`, "",{"version":3,"sources":["webpack://./src/static/css/font/iconfont.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAE,uBAAuB;EAChD;;iEAE2D;AAC7D;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: \"iconfont\"; /* Project id 4591508 */\n  src: url('iconfont.woff2?t=1725869128183') format('woff2'),\n       url('iconfont.woff?t=1725869128183') format('woff'),\n       url('iconfont.ttf?t=1725869128183') format('truetype');\n}\n\n.iconfont {\n  font-family: \"iconfont\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-xiazai1:before {\n  content: \"\\e637\";\n}\n\n.icon-yindaojiantou:before {\n  content: \"\\e72e\";\n}\n\n.icon-success:before {\n  content: \"\\e67f\";\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

export const abilityList = [
    {
        url: '社区医院.png',
        title: '卫生室/医务室',
        content: "以病人信息化为核心，提供诊前-诊中-诊后一站式无纸化办公诊疗服务"
    },
    {
        url: '诊所.png',
        title: '诊所/个人诊所',
        content: "以病人信息化为核心，提供简化管理-便捷开方的无纸化管理模式"
    },
    {
        url: '中医馆.png',
        title: '中医诊所/中医馆',
        content: "以病人信息为核心，打造开处方-理疗管理-客户跟踪-会员管理等智能信息化"
    },
    {
        url: '门诊部.png',
        title: '专科/综合门诊部',
        content: "为医疗机构实现内部信息化管理，打造闭环链接的一站式信息化管理服务"
    },
    {
        url: '定制化.png',
        title: '定制化服务',
        content: "以数据为核心，提供个性化定制一站式信息管理解决方案，高端医疗管理软件解决方案"
    },
]

export const advantageList = [
    {
        url: '诊疗服务.png',
        title: '全流程诊疗服务',
        content: "快捷的病历、处方填写以及智能化的辅助诊疗的介入，都大大缩短医生诊断时间，提高诊所的诊疗效率"
    },
    {
        url: '药房管理.png',
        title: '智能药房管理',
        content: "完善药房管理功能，新增出入库管理、库存盘点、药品调价、供应商管理等药房管理功能，更好地管理药品库存"
    },
    {
        url: '财务管理 .png',
        title: '精细化的财务管理',
        content: "完善的财务管理，提供收费、退费、欠费、日结、查询等财务功能，帮助诊所管理者方便快捷掌握诊所财务状况"
    },
    {
        url: '运营.png',
        title: '诊所品牌运营推广',
        content: "运用微信定制诊所转型的服务平台，同时开通会员服务功能，在原有微信推广的基础上，提升诊所的平台推广能力"
    }
]

export const coreList = [
    {
        url: '预约挂号.png',
        title: '预约挂号',
        content: "微信、电话预约，现场挂号叫号，患者方便快捷，诊所资源调配轻松有序"
    },
    {
        url: '门诊管理.png',
        title: '门诊管理',
        content: "门诊前台患者挂号收费等全数据打通，医生智能开处方，科学辅助诊疗"
    },
    {
        url: '药房管理.png',
        title: '药房管理',
        content: "药品进销存管理、全局动态库存预警、药品入库、库存、销售统计分析"
    },
    {
        url: '电子病例.png',
        title: '电子病例',
        content: "免输入快捷选录，智能辅助诊断。病历永久安全保存、检验报告多维度呈现"
    },
    {
        url: '会员营销.png',
        title: '会员营销',
        content: "微信营销，会员积分储值、折扣，复诊、慢病、满意度等随访直达患者。"
    },
    {
        url: '数据统计.png',
        title: '数据统计',
        content: "强大的数据沉淀、查询、分析功能，多维度的经营统计报表，"
    },
    {
        url: '针灸理疗管理.png',
        title: '针灸理疗管理',
        content: "门诊管理的同时能够管理到针灸理疗项目，对各种理疗项目进行执行操作"
    },
    {
        url: '检验检查.png',
        title: '检验检查',
        content: "支持主流诊所检验设备的数据采集，检验报告自动和患者病历连接。"
    },
    {
        url: '电子发票.png',
        title: '电子发票',
        content: "支持开具电子发票，并实时同步至患者邮箱和手机"
    },
    {
        url: '患者会员管理.png',
        title: '患者会员管理',
        content: "对患者诊前、诊中、诊后的全方位服务。支持多种会员设定和优惠促销工具"
    },
    {
        url: '卡项管理.png',
        title: '卡项管理',
        content: "支持月卡、季卡、年卡、次卡等各种卡项的设置，满足不同的营销场景"
    },
    {
        url: '医保对接.png',
        title: '医保对接',
        content: "医保的对接和维护，提供医保政策解读，技术咨询及现场支持。"
    },
    {
        url: '三方对接.png',
        title: '三方对接',
        content: "中药颗粒发药机，第三方中药代煎无缝对接，保证诊所高效运营。"
    },
    {
        url: '诊后随访.png',
        title: '诊后随访',
        content: "专家医生随访，让你不再有后顾之忧。电子随访档案，可随时查询患者状况"
    },
    {
        url: '用药知识库.png',
        title: '用药知识库',
        content: "超十万种药品信息库及药品说明书参考，用药风险提示，规范门诊用药行为。"
    },
    {
        url: '多端适配.png',
        title: '多端适配',
        content: "电脑、手机、平板多种屏幕尺寸适配，满足不同应用场景"
    },
]

export const brandList = [
    {
        url: '操作简单.png',
        title: '操作简单',
        content: "软件操作简单易用，软件包教包会，植入移动互联网管理模式与链接第三方平台接口；界面美观，易于上手。"
    },
    {
        url: '持续更新.png',
        title: '持续更新',
        content: "采用B/S结构，云端部署的 SaaS系统,随时随地登陆网站即刻使用，迭代快、紧密联系用户，快速响应，维护方便。"
    },
    {
        url: '低投入.png',
        title: '低投入零部署',
        content: "不需要购买服务器、不需要聘请技术人员、不需要高成本安装调试、硬件要求低、功能简单易学、投入成本少。"
    },
    {
        url: '数据安全.png',
        title: '数据安全',
        content: "使用了阿里云和华为云两套高安全等级的云数据服务器，绝对保障用户数据安全，7x24 小时全年不间断。"
    },
]
import setting from './config.js'
import $ from 'jquery'

function ajaxRequest(url, method, data, successCallback, errorCallback) {
    $.ajax({
        url: setting.BASE_API + setting.BAEE_suffix + url,
        type: method.toLocaleUpperCase(),
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        success: function (response) {
            // 未设置状态码则默认成功状态
            const code = response.code || 200;
            if (typeof successCallback === 'function' && typeof errorCallback === 'function') {
                if (code !== 200) {
                    errorCallback(response)
                } else {
                    successCallback(response);
                }
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (typeof errorCallback === 'function') {
                errorCallback(jqXHR, textStatus, errorThrown);
            }
        }
    });
}

export default ajaxRequest
import ajaxRequest from 'utils/request.js'
import $ from 'jquery'
const idDoms = ['businessType', 'businessName', 'linkman', 'mobile', 'code']
let loadend = false
let countdown = 60

$(document).ready(function () {
    $('#app-main').css('display', 'block')
})

$(window).scroll(function () {
    $('.yx-anchor').each(function (key, item) {
        if ($(window).scrollTop() + 200 > $(item).offset().top) {
            $('.yx-header-nav-bar .nav-bar-item').removeClass('active').eq(key).addClass('active');
        }
    })
})

$('.yx-header-nav-logo').click(() => {
    document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
    })
})

$('.yx-header-nav-btn .btn1').on('click', () => {
    window.open('https://med.yongxingcloud.com')
})

$('.yx-header-nav-btn .btn2,.yx-fixed .btn1,.yx-static .btn1,.phoneFixed .btn1,.yx-content-price .roundBtn').on('click', () => {
    document.querySelector('.dog').showModal()
    $('.dog-content').css('display', 'block')
    $('.dog-success').css('display', 'none')
})

$('.nav-bar-item').on('click', (e) => {
    const anchor = e.target.getAttribute('data-anchor')
    const location = document.querySelector(`#${anchor}`).offsetTop - 80
    document.documentElement.scrollTo({
        top: location,
        behavior: "smooth",
    })
})

$('.dog-close').on('click', () => {
    document.querySelector('.dog').close()
})

$('.dog-success-back').on('click', () => {
    document.querySelector('.dog').close()
})

// 下载
$('.yx-footer-download-ul li').on('click', (e) => {
    if (!e.currentTarget.getAttribute('data-url')) return
    window.open(e.currentTarget.getAttribute('data-url'), '_self')
})


class RequestAjax {
    constructor(url, methods, data) {
        this.url = url
        this.methods = methods
        this.data = data
    }
    request() {
        return new Promise((resolve, reject) => {
            ajaxRequest(this.url, this.methods, this.data, (response) => {
                resolve(response)
            }, (jqXHR, textStatus, error) => {
                reject(jqXHR, textStatus, error)
            });
        })
    }
}

$('.dog-code .btn2').on('click', () => {
    if (countdown !== 60) return
    if (!$('#mobile').val()) {
        return alert('请输入手机号')
    }
    const result = new RequestAjax('/yx/trial-user-info/sendMsg', 'post', {
        mobile: $('#mobile').val(),
        scene: 6,
    })
    result.request().then(() => {
        getTime()
        let timeId = setInterval(() => {
            getTime()
        }, 1000)
        function getTime() {
            if (countdown === 0) {
                countdown = 60;
                $('.dog-code .btn2').text("发送验证码");
                clearInterval(timeId);
            } else {
                $('.dog-code .btn2').text(`${countdown}秒后重试`);
                $('.dog-code .btn2').css('cursor', 'default')
                countdown--;
            }
        }
    }).catch(err => {
        alert(err.msg)
    })
})

// 提交  
const queryForm = document.getElementById('queryForm')
queryForm.addEventListener("submit", function (e) {
    e.preventDefault();
    checkRequired();
})

// 判断提交
function checkRequired() {
    if (loadend) return
    let params = {}
    idDoms.forEach((item) => {
        if ($(`#${item}`).val().trim() === "") {
            $(`#${item}`).next().css('display', 'block')
        } else {
            $(`#${item}`).next().css('display', 'none')
        }
    })
    const e = idDoms.every(item => $(`#${item}`).val().trim())
    if (!e) return
    idDoms.forEach(item => {
        params[item] = $(`#${item}`).val().trim()
    })
    loadend = true
    $('.dog-form-item-submit .dog-btn').addClass('bntLoadingActive')
    const result = new RequestAjax('/yx/trial-user-info/add', 'post', params)
    result.request().then(() => {
        $('.dog-content').css('display', 'none')
        $('.dog-success').css('display', 'block')
        queryForm.reset()
    }).finally(() => {
        loadend = false
        $('.dog-form-item-submit .dog-btn').removeClass('bntLoadingActive')
    })
}